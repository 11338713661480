.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

td {
  word-wrap: break-word !important;
}

.MuiDataGrid-columnHeader {
  background-color: #9f0808 !important;
  color: white !important;
}

.darkside {
  background-color: black !important;
  color: white !important;
  min-height: 100vh;
}

.darkside td,
.darkside tr,
.darkside table,
.darkside thead,
.darkside th {
  /*  background-color: black !important;*/
  border-color: white !important;
  border: 1px solid;
}

.darkside td.black,
.darkside th.black {
  color: black;
}

.pdfIframe {
  display: none;
}

/*.MuiTablePagination-root {
  display: none;
}*/

.react-draggable {
  cursor: pointer;
}

.react-draggable:hover {
  border: dashed 1px #dddddd !important;
}

.react-draggable-dragging {
  border: dashed 1px #dddddd !important;
}

@media only screen and (max-width: 768px) {
  .MuiTableCell-root {
    padding: 2px;
  }
}

.darkside th {
  font-weight: 900 !important;
  line-height: 16px;
}

.darkside td {
  font-size: 16px !important;
  line-height: 6px;
}

.pomView {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.startDiv {
  margin-right: 20px;
  margin-left: 20px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 100;
}

.boardTable {
  padding: 2px !important;
}
